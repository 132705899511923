
import { Component, Vue } from 'vue-property-decorator'
import { apiMessageDetail, apiFactoryAdd, apiFactoryEdit } from '@/api/shop'
import { PageMode } from '@/utils/type'
import MaterialSelect from '@/components/material-select/index.vue'

@Component({
  components: {
    MaterialSelect
  }
})
export default class TaskEdit extends Vue {
  /** S Data **/
  mode: string = PageMode.ADD; // 当前页面: add-添加角色 edit-编辑角色

  // 表
  lists: Array<object> = [];

  // 表单数据
  form: any = {
    name: '',
    address: '',
    status: 1,
    sort: ''
  };

  // 表单校验
  rules: object = {
    name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
    address: [{ required: true, message: '请输入地址', trigger: 'blur' }]
  };

  factoryList: any[] = []

  /** E Data **/

  /** S Methods **/
  // 提交表单
  onSubmit (formName: string) {
    const refs = this.$refs[formName] as HTMLFormElement
    refs.validate((valid: boolean): any => {
      if (!valid) return
      // 请求发送
      switch (this.mode) {
        case PageMode.ADD:
          return this.handleFactoryAdd()
        case PageMode.EDIT:
          return this.handleNoticeEdit()
      }
      this.handleNoticeEdit()
    })
  }

  // 添加
  async handleFactoryAdd (): Promise<void> {
    await apiFactoryAdd(this.form)
    setTimeout(() => this.$router.go(-1), 500)
  }

  // 编辑
  async handleNoticeEdit (): Promise<void> {
    await apiFactoryEdit(this.form)
    setTimeout(() => this.$router.go(-1), 500)
  }

  // 初始化表单数据
  async initFormDataForNoticeEdit (): Promise<void> {
    const res = await apiMessageDetail({
      id: this.form.id
    })
    Object.keys(res).map((item) => {
      this.$set(this.form, item, res[item])
    })
  }

  /** E Methods **/

  /** S Life Cycle **/
  created () {
    const query: any = this.$route.query
    if (query.mode) this.mode = query.mode

    if (this.mode === PageMode.EDIT) {
      this.form.id = query.id * 1
      this.initFormDataForNoticeEdit()
    }
  }

  /** E Life Cycle **/
}
